import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default () => ({
    slider: null,
    slides: [],
    interval: null,
    type: null,
    init() {
        this.type = this.$root.dataset.type;
        this.slides = Array.from(this.$root.querySelectorAll('.swiper-slide'));
        this.slider = new Swiper(this.$root, this.typeOptions());

        if (this.$root.dataset.type === 'search-types') {
            window.Livewire.on('product-search:updated', () => this.slider.init(this.$root));
        }
    },
    fadeSlides(event, opacity = 0.6) {
        const inView = Math.round(
            this.typeOptions().breakpoints[event.currentBreakpoint]?.slidesPerView || this.typeOptions().slidesPerView
        );
        this.slides.forEach(elem => (elem.style.opacity = opacity));
        this.slides.slice(event.activeIndex, event.activeIndex + inView).forEach(elem => (elem.style.opacity = 1));
    },
    fadeOverlay() {
        const div = document.createElement('div');
        div.classList.add(
            'fade-overlay',
            'absolute',
            'z-10',
            'inset-0',
            'bg-gradient-to-r',
            'from-transparent',
            'to-gray-900',
            'from-80%',
            'pointer-events-none'
        );
        return div;
    },
    typeOptions() {
        if (this.type === '1-to-3') {
            return {
                modules: [Navigation, Pagination],
                breakpoints: {
                    640: {
                        slidesPerView: this.slides.length > 2 ? 2.1 : 2,
                        slidesPerGroup: 2,
                        spaceBetween: 24,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 2,
                        },
                        pagination: {
                            enabled: this.slides.length > 2,
                        },
                    },
                    1024: {
                        slidesPerView: this.slides.length > 3 ? 3.2 : 3,
                        slidesPerGroup: 3,
                        spaceBetween: 32,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 3,
                        },
                        pagination: {
                            enabled: this.slides.length > 3,
                        },
                    },
                },
                slidesPerView: 1.1,
                spaceBetween: 24,
                centeredSlides: true,
                centerInsufficientSlides: true,
                watchSlidesProgress: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    enabled: false,
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    afterInit: event => this.fadeSlides(event),
                    slideChange: event => this.fadeSlides(event),
                    resize: event => this.fadeSlides(event),
                },
            };
        }

        if (this.type === 'blog') {
            return {
                modules: [Navigation],
                breakpoints: {
                    480: {
                        slidesPerView: this.slides.length > 2 ? 2.3 : 2,
                        slidesPerGroup: 2,
                        spaceBetween: 12,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 2,
                        },
                    },
                    640: {
                        slidesPerView: this.slides.length > 3 ? 3.3 : 3,
                        slidesPerGroup: 3,
                        spaceBetween: 12,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 3,
                        },
                    },
                    960: {
                        slidesPerView: this.slides.length > 4 ? 4.3 : 4,
                        slidesPerGroup: 4,
                        spaceBetween: 12,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 4,
                        },
                    },
                    1430: {
                        slidesPerView: this.slides.length > 5 ? 5.3 : 5,
                        slidesPerGroup: 5,
                        spaceBetween: 12,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 5,
                        },
                    },
                },
                slidesPerView: 1.4,
                spaceBetween: 12,
                centeredSlides: false,
                centerInsufficientSlides: true,
                watchSlidesProgress: true,
                navigation: {
                    enabled: false,
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    afterInit: swiper => {
                        if (!swiper.isEnd) {
                            this.$root.prepend(this.fadeOverlay());
                        }
                    },
                    slideChange: swiper => {
                        if (swiper.isEnd) {
                            this.$root.querySelector('.fade-overlay')?.remove();
                        } else {
                            if (!this.$root.querySelector('.fade-overlay')) {
                                this.$root.prepend(this.fadeOverlay());
                            }
                        }
                    },
                },
            };
        }

        if (this.type === 'search-types') {
            const initialSlide =
                Array.from(this.$root.querySelectorAll('.swiper-slide')).findIndex(elem =>
                    elem.classList.contains('active')
                ) || 0;
            return {
                modules: [Navigation],
                breakpoints: {
                    580: {
                        initialSlide: initialSlide,
                        slidesPerView: this.slides.length > 3 ? 3.1 : 3,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 3,
                        },
                    },
                    840: {
                        initialSlide: initialSlide,
                        slidesPerView: this.slides.length > 4 ? 4.1 : 4,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: this.slides.length > 4,
                        },
                    },
                    1080: {
                        slidesPerView: 5,
                        centeredSlides: false,
                        centerInsufficientSlides: false,
                        watchSlidesProgress: true,
                        navigation: {
                            enabled: false,
                        },
                    },
                },
                initialSlide: initialSlide,
                slidesPerView: this.slides.length > 2 ? 2.1 : 2,
                centeredSlides: false,
                centerInsufficientSlides: false,
                spaceBetween: 8,
                watchSlidesProgress: true,
                navigation: {
                    enabled: this.slides.length > 2,
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    afterInit: event => this.fadeSlides(event),
                    slideChange: event => this.fadeSlides(event),
                    resize: event => this.fadeSlides(event),
                },
            };
        }

        return {
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };
    },
});
